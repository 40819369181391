<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-slide</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Playground</v-toolbar-title>
      <v-spacer />
    </v-app-bar>

    <v-alert text type="info">
      <Markdown :value="mdText" />
    </v-alert>

    <v-card>
      <v-system-bar>Paperless-Users</v-system-bar>
      <v-list>
        <v-list-item v-for="user in users" :key="user.id">
          <v-list-item-content>
            <v-list-item-title>{{ user.username }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ user.groups.map((el) => getGroup(el).name).join(", ") }}
              {{ user.user_permissions.join(", ") }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :color="user.is_active ? 'success' : 'grey lighten-3'">{{
              user.is_superuser
                ? "mdi-account-tie"
                : user.is_staff
                ? "mdi-account-school"
                : "mdi-account"
            }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <pre>{{ groups }}</pre>
    </v-card>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import Markdown from "@/components/Markdown.vue";

const mdText = `
## Tests für User-Management
Users können mit Post erstellt werden. Admin-Synchro-Job wäre somit möglich.

Offene Fragen:
- wie macht man tokens? -> über UI oder per Endpoint /api/token/
- wie kommt man per Rest an diese? -> über Endpoint /api/token/ (aber mit username und password, leeres Passwort geht nicht)

`;
export default defineComponent({
  name: "Playground",
  components: { Markdown },
  data() {
    return {
      mdText,
      token: "b9498b25987eea28a38f6df92636109c16bfc329",
      groups: [],
      users: [],
    };
  },
  computed: {
    getOptions() {
      return {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
          Host: "localhost:8000",
        },
      };
    },
  },
  watch: {},
  async created() {
    await this.fetchData();
    await this.fetchUsers();

    // test token
    var formData = new FormData();
    formData.append("username", "student.test");
    formData.append("password", "");
    var options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        //        Authorization: "Token " + this.token,
        Host: "localhost:8000",
      },
      body: formData,
    };

    const result = await (
      await fetch("http://localhost:8000/api/token/", options)
    ).json();
    console.log(result);
  },
  methods: {
    getGroup(id) {
      return this.groups.find((el) => el.id == id);
    },
    async fetchData() {
      this.groups = (
        await (
          await fetch("http://localhost:8000/api/groups/", this.getOptions)
        ).json()
      ).results;
    },
    async fetchUsers() {
      this.users = (
        await (
          await fetch("http://localhost:8000/api/users/", this.getOptions)
        ).json()
      ).results;
    },
  },
});
</script>
